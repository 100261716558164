import { default as _404vPzf1XjGQ3Meta } from "/usr/src/app/pages/404.vue?macro=true";
import { default as accountdWl6ticfZzMeta } from "/usr/src/app/pages/account.vue?macro=true";
import { default as bundlesZvV7qeM3ZSMeta } from "/usr/src/app/pages/bundles.vue?macro=true";
import { default as cart37DfDFSHEiMeta } from "/usr/src/app/pages/cart.vue?macro=true";
import { default as indexJPEr6nErnHMeta } from "/usr/src/app/pages/checkout/index.vue?macro=true";
import { default as contactUso6OlRP2aEhMeta } from "/usr/src/app/pages/contactUs.vue?macro=true";
import { default as _91id_93mZdkyxfClcMeta } from "/usr/src/app/pages/custom/[id].vue?macro=true";
import { default as faqDkOohHQxeIMeta } from "/usr/src/app/pages/faq.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as order_45placed6k6OtQdSpLMeta } from "/usr/src/app/pages/order-placed.vue?macro=true";
import { default as contentqWkrqCQFuAMeta } from "/usr/src/app/pages/pages/[key]/content.vue?macro=true";
import { default as payment_45failedSg3MXAHmCsMeta } from "/usr/src/app/pages/payment-failed.vue?macro=true";
import { default as processing_45transactionZ4vgdVuUrVMeta } from "/usr/src/app/pages/processing-transaction.vue?macro=true";
import { default as _91id_93BZxSEuLtmFMeta } from "/usr/src/app/pages/products/[id].vue?macro=true";
import { default as indextGTcZpyi4NMeta } from "/usr/src/app/pages/products/index.vue?macro=true";
import { default as register6aSL4CEIZgMeta } from "/usr/src/app/pages/register.vue?macro=true";
import { default as resety7s7g96HwmMeta } from "/usr/src/app/pages/reset.vue?macro=true";
import { default as staticPNyfWeeIxTMeta } from "/usr/src/app/pages/static.vue?macro=true";
import { default as verifyRGGI4FzELVMeta } from "/usr/src/app/pages/verify.vue?macro=true";
import { default as wishlistuT8akadtc5Meta } from "/usr/src/app/pages/wishlist.vue?macro=true";
export default [
  {
    name: _404vPzf1XjGQ3Meta?.name ?? "404",
    path: _404vPzf1XjGQ3Meta?.path ?? "/404",
    meta: _404vPzf1XjGQ3Meta || {},
    alias: _404vPzf1XjGQ3Meta?.alias || [],
    redirect: _404vPzf1XjGQ3Meta?.redirect,
    component: () => import("/usr/src/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: accountdWl6ticfZzMeta?.name ?? "account",
    path: accountdWl6ticfZzMeta?.path ?? "/account",
    meta: accountdWl6ticfZzMeta || {},
    alias: accountdWl6ticfZzMeta?.alias || [],
    redirect: accountdWl6ticfZzMeta?.redirect,
    component: () => import("/usr/src/app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: bundlesZvV7qeM3ZSMeta?.name ?? "bundles",
    path: bundlesZvV7qeM3ZSMeta?.path ?? "/bundles",
    meta: bundlesZvV7qeM3ZSMeta || {},
    alias: bundlesZvV7qeM3ZSMeta?.alias || [],
    redirect: bundlesZvV7qeM3ZSMeta?.redirect,
    component: () => import("/usr/src/app/pages/bundles.vue").then(m => m.default || m)
  },
  {
    name: cart37DfDFSHEiMeta?.name ?? "cart",
    path: cart37DfDFSHEiMeta?.path ?? "/cart",
    meta: cart37DfDFSHEiMeta || {},
    alias: cart37DfDFSHEiMeta?.alias || [],
    redirect: cart37DfDFSHEiMeta?.redirect,
    component: () => import("/usr/src/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: indexJPEr6nErnHMeta?.name ?? "checkout",
    path: indexJPEr6nErnHMeta?.path ?? "/checkout",
    meta: indexJPEr6nErnHMeta || {},
    alias: indexJPEr6nErnHMeta?.alias || [],
    redirect: indexJPEr6nErnHMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: contactUso6OlRP2aEhMeta?.name ?? "contactUs",
    path: contactUso6OlRP2aEhMeta?.path ?? "/contactUs",
    meta: contactUso6OlRP2aEhMeta || {},
    alias: contactUso6OlRP2aEhMeta?.alias || [],
    redirect: contactUso6OlRP2aEhMeta?.redirect,
    component: () => import("/usr/src/app/pages/contactUs.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mZdkyxfClcMeta?.name ?? "custom-id",
    path: _91id_93mZdkyxfClcMeta?.path ?? "/custom/:id()",
    meta: _91id_93mZdkyxfClcMeta || {},
    alias: _91id_93mZdkyxfClcMeta?.alias || [],
    redirect: _91id_93mZdkyxfClcMeta?.redirect,
    component: () => import("/usr/src/app/pages/custom/[id].vue").then(m => m.default || m)
  },
  {
    name: faqDkOohHQxeIMeta?.name ?? "faq",
    path: faqDkOohHQxeIMeta?.path ?? "/faq",
    meta: faqDkOohHQxeIMeta || {},
    alias: faqDkOohHQxeIMeta?.alias || [],
    redirect: faqDkOohHQxeIMeta?.redirect,
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginRGcvZC480vMeta?.name ?? "login",
    path: loginRGcvZC480vMeta?.path ?? "/login",
    meta: loginRGcvZC480vMeta || {},
    alias: loginRGcvZC480vMeta?.alias || [],
    redirect: loginRGcvZC480vMeta?.redirect,
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: order_45placed6k6OtQdSpLMeta?.name ?? "order-placed",
    path: order_45placed6k6OtQdSpLMeta?.path ?? "/order-placed",
    meta: order_45placed6k6OtQdSpLMeta || {},
    alias: order_45placed6k6OtQdSpLMeta?.alias || [],
    redirect: order_45placed6k6OtQdSpLMeta?.redirect,
    component: () => import("/usr/src/app/pages/order-placed.vue").then(m => m.default || m)
  },
  {
    name: contentqWkrqCQFuAMeta?.name ?? "pages-key-content",
    path: contentqWkrqCQFuAMeta?.path ?? "/pages/:key()/content",
    meta: contentqWkrqCQFuAMeta || {},
    alias: contentqWkrqCQFuAMeta?.alias || [],
    redirect: contentqWkrqCQFuAMeta?.redirect,
    component: () => import("/usr/src/app/pages/pages/[key]/content.vue").then(m => m.default || m)
  },
  {
    name: payment_45failedSg3MXAHmCsMeta?.name ?? "payment-failed",
    path: payment_45failedSg3MXAHmCsMeta?.path ?? "/payment-failed",
    meta: payment_45failedSg3MXAHmCsMeta || {},
    alias: payment_45failedSg3MXAHmCsMeta?.alias || [],
    redirect: payment_45failedSg3MXAHmCsMeta?.redirect,
    component: () => import("/usr/src/app/pages/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: processing_45transactionZ4vgdVuUrVMeta?.name ?? "processing-transaction",
    path: processing_45transactionZ4vgdVuUrVMeta?.path ?? "/processing-transaction",
    meta: processing_45transactionZ4vgdVuUrVMeta || {},
    alias: processing_45transactionZ4vgdVuUrVMeta?.alias || [],
    redirect: processing_45transactionZ4vgdVuUrVMeta?.redirect,
    component: () => import("/usr/src/app/pages/processing-transaction.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BZxSEuLtmFMeta?.name ?? "products-id",
    path: _91id_93BZxSEuLtmFMeta?.path ?? "/products/:id()",
    meta: _91id_93BZxSEuLtmFMeta || {},
    alias: _91id_93BZxSEuLtmFMeta?.alias || [],
    redirect: _91id_93BZxSEuLtmFMeta?.redirect,
    component: () => import("/usr/src/app/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indextGTcZpyi4NMeta?.name ?? "products",
    path: indextGTcZpyi4NMeta?.path ?? "/products",
    meta: indextGTcZpyi4NMeta || {},
    alias: indextGTcZpyi4NMeta?.alias || [],
    redirect: indextGTcZpyi4NMeta?.redirect,
    component: () => import("/usr/src/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: register6aSL4CEIZgMeta?.name ?? "register",
    path: register6aSL4CEIZgMeta?.path ?? "/register",
    meta: register6aSL4CEIZgMeta || {},
    alias: register6aSL4CEIZgMeta?.alias || [],
    redirect: register6aSL4CEIZgMeta?.redirect,
    component: () => import("/usr/src/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resety7s7g96HwmMeta?.name ?? "reset",
    path: resety7s7g96HwmMeta?.path ?? "/reset",
    meta: resety7s7g96HwmMeta || {},
    alias: resety7s7g96HwmMeta?.alias || [],
    redirect: resety7s7g96HwmMeta?.redirect,
    component: () => import("/usr/src/app/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: staticPNyfWeeIxTMeta?.name ?? "static",
    path: staticPNyfWeeIxTMeta?.path ?? "/static",
    meta: staticPNyfWeeIxTMeta || {},
    alias: staticPNyfWeeIxTMeta?.alias || [],
    redirect: staticPNyfWeeIxTMeta?.redirect,
    component: () => import("/usr/src/app/pages/static.vue").then(m => m.default || m)
  },
  {
    name: verifyRGGI4FzELVMeta?.name ?? "verify",
    path: verifyRGGI4FzELVMeta?.path ?? "/verify",
    meta: verifyRGGI4FzELVMeta || {},
    alias: verifyRGGI4FzELVMeta?.alias || [],
    redirect: verifyRGGI4FzELVMeta?.redirect,
    component: () => import("/usr/src/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: wishlistuT8akadtc5Meta?.name ?? "wishlist",
    path: wishlistuT8akadtc5Meta?.path ?? "/wishlist",
    meta: wishlistuT8akadtc5Meta || {},
    alias: wishlistuT8akadtc5Meta?.alias || [],
    redirect: wishlistuT8akadtc5Meta?.redirect,
    component: () => import("/usr/src/app/pages/wishlist.vue").then(m => m.default || m)
  }
]